import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React, { useRef } from "react";
import Layout from "../components/layout";

import { Helmet } from "react-helmet";
import Hero from "../components/hero";
import AiSection from "../components/ai-section";
import ServicesSection from "../components/services-section";
import { Button, Col, Container, Row } from "react-bootstrap";
import parse from "html-react-parser";
import { GatsbyImage } from "gatsby-plugin-image";
import { FaCheck } from "react-icons/fa";
import ContactForm from "../components/contactForm";
import BgImageUnlock from "../images/bg img unlock.svg";
import BgImageContact from "../images/bg img contact us.svg";
import BgImageContactMobile from "../images/bg contact mobile.svg";

const IndexPage = () => {
	const tl = useRef();
	const {
		wpHomePage: {
			seoFields,
			homePageFields: {
				aiImplementationSection,
				aiSection,
				contactUsFormSection,
				contactUsSection,
				hasCustomerSection,
				hasPartnerSection,
				customerSection,
				heroSection,
				officeDetailsSection,
				partnersSection,
				servicesSection,
			},
		},

		site: {
			siteMetadata: { siteUrl },
		},
	} = useStaticQuery(graphql`
		query {
			wpHomePage {
				seoFields {
					title
					opengraphTitle
					opengraphDescription
					metaDescription
					localBusinessSchema
					image {
						node {
							altText
							link
							localFile {
								childImageSharp {
									original {
										height
										width
									}
								}
							}
						}
					}
				}
				homePageFields {
					aiImplementationSection {
						body
						button1Text
						button2Text
						item1
						item2
						item3
						title
						subtext
						image {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: FULL_WIDTH
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					aiSection {
						title
						text
						buttonText
						business1 {
							description
							title
							image {
								node {
									altText
									link
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
						business2 {
							description
							title
							image {
								node {
									altText
									link
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
						business3 {
							description
							title
							image {
								node {
									altText
									link
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
					}
					contactUsFormSection {
						text
						title
					}
					contactUsSection {
						buttonText
						subtext
						text
						title
					}
					hasCustomerSection
					hasPartnerSection
					customerSection {
						title
						review1 {
							name
							review
							avatar {
								node {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
						review2 {
							name
							review
							avatar {
								node {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
					}
					heroSection {
						body
						btnText
						hasReviews
						title
						googleReview {
							btnText
							buttonLink
							rating
							title
							icon {
								node {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
					}
					officeDetailsSection {
						address1 {
							address
							title
							icon {
								node {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
						address2 {
							address
							addressLink
							phoneNumber
							title
							icon {
								node {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
						address3 {
							address
							addressLink
							phoneNumber
							title
							icon {
								node {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
					}
					partnersSection {
						title
						partner1 {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						partner2 {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						partner3 {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						partner4 {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						partner5 {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						partner6 {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						partner7 {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					servicesSection {
						buttonText
						service1 {
							title
							buttonText
							body
							icon {
								node {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
						service2 {
							body
							buttonText
							title
							icon {
								node {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
						service3 {
							body
							buttonText
							title
							icon {
								node {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	const schemaLocal = seoFields.localBusinessSchema;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schemaLocal}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields.title}
				description={seoFields.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: seoFields.opengraphTitle,
					description: seoFields.opengraphDescription,
					images: [
						{
							url: `${seoFields.image?.node.link}`,
							width: `${seoFields.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFields.image?.node.localFile.childImageSharp.original.height}`,
							alt: `${seoFields.image?.node.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					<section className="bg-light-grey py-5 py-lg-7">
						<Hero
							title={heroSection.title}
							description={heroSection.body}
							buttonText={heroSection.btnText}
						/>
					</section>
					<section className="bg-white py-5 py-lg-7">
						<ServicesSection
							title1={servicesSection.service1.title}
							description1={servicesSection.service1.body}
							linkText1={servicesSection.service1.buttonText}
							image1={
								servicesSection.service1.icon.node.localFile.childImageSharp
									.gatsbyImageData
							}
							title2={servicesSection.service2.title}
							description2={servicesSection.service2.body}
							linkText2={servicesSection.service2.buttonText}
							image2={
								servicesSection.service2.icon.node.localFile.childImageSharp
									.gatsbyImageData
							}
							title3={servicesSection.service3.title}
							description3={servicesSection.service3.body}
							linkText3={servicesSection.service3.buttonText}
							image3={
								servicesSection.service3.icon.node.localFile.childImageSharp
									.gatsbyImageData
							}
							buttonText={servicesSection.buttonText}
						/>
					</section>
					<section className="py-5 py-lg-7 d-none d-lg-block bg-light-grey position-relative">
						<div
							style={{ zIndex: 0 }}
							className="position-absolute end-0 bottom-0"
						>
							<img className="" src={BgImageUnlock} alt="" />
						</div>
						<Container style={{ zIndex: 1 }} className="position-relative">
							<Row className="pb-5">
								<Col lg={8} xl={6}>
									{parse(aiSection.title)}{" "}
									<p className="pt-3">{aiSection.text}</p>
								</Col>
							</Row>
						</Container>
						<AiSection
							img1={aiSection.business1.image.node.link}
							img2={aiSection.business2.image.node.link}
							img3={aiSection.business3.image.node.link}
							title1={aiSection.business1.title}
							title2={aiSection.business2.title}
							title3={aiSection.business3.title}
							des1={aiSection.business1.description}
							des2={aiSection.business2.description}
							des3={aiSection.business3.description}
						/>
						<Container style={{ zIndex: 1 }} className="position-relative">
							<Row className="pt-4">
								<Col lg={8} xl={6}>
									<Link className="dark-blue-link" to="#form">
										{aiSection.buttonText} <span className="ms-2">></span>
									</Link>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 py-lg-7 d-lg-none bg-light-grey position-relative">
						<div
							style={{ zIndex: 0 }}
							className="position-absolute end-0 bottom-0"
						>
							<img className="" src={BgImageUnlock} alt="" />
						</div>
						<Container style={{ zIndex: 1 }} className="position-relative">
							<Row className="pb-5">
								<Col>
									{parse(aiSection.title)}{" "}
									<p className="pt-3 pb-4">{aiSection.text}</p>
									<div className="pb-4">
										<div style={{ overflow: "hidden", borderRadius: "24px" }}>
											<GatsbyImage
												class="w-100"
												image={
													aiSection.business1.image.node.localFile
														.childImageSharp.gatsbyImageData
												}
												alt={aiSection.business1.title}
											/>
										</div>
										<h3 className="text-dark-blue pt-4 fs-2">
											{aiSection.business1.title}
										</h3>
										<p>{aiSection.business1.description}</p>
									</div>
									<div className="pb-4">
										<div style={{ overflow: "hidden", borderRadius: "24px" }}>
											<GatsbyImage
												class="w-100"
												image={
													aiSection.business2.image.node.localFile
														.childImageSharp.gatsbyImageData
												}
												alt={aiSection.business2.title}
											/>
										</div>
										<h3 className="text-dark-blue pt-4 fs-2">
											{aiSection.business2.title}
										</h3>
										<p>{aiSection.business2.description}</p>
									</div>
									<div>
										<div style={{ overflow: "hidden", borderRadius: "24px" }}>
											<GatsbyImage
												class="w-100"
												image={
													aiSection.business3.image.node.localFile
														.childImageSharp.gatsbyImageData
												}
												alt={aiSection.business3.title}
											/>
										</div>
										<h3 className="text-dark-blue pt-4 fs-2">
											{aiSection.business3.title}
										</h3>
										<p>{aiSection.business3.description}</p>
									</div>
								</Col>
							</Row>
						</Container>

						<Container style={{ zIndex: 1 }} className="position-relative">
							<Row>
								<Col>
									<Link className="dark-blue-link" to="#form">
										{aiSection.buttonText} <span className="ms-2">></span>
									</Link>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 py-lg-7 bg-white">
						<Container>
							<Row className="gy-4 gy-lg-0 gx-lg-5">
								<Col className="d-lg-none" lg={6}>
									<div style={{ overflow: "hidden", borderRadius: "24px" }}>
										<GatsbyImage
											class="w-100 "
											image={
												aiImplementationSection.image.node.localFile
													.childImageSharp.gatsbyImageData
											}
											alt={aiImplementationSection.subtext}
										/>
									</div>
								</Col>
								<Col lg={6}>
									<p className="dm-bold gradient-text">
										{aiImplementationSection.subtext}
									</p>
									{parse(aiImplementationSection.title)}
									<p className="py-4">{aiImplementationSection.body}</p>
									<div className="d-flex align-items-center pb-3">
										<FaCheck className="text-dark-blue me-3" />{" "}
										<p
											style={{ opacity: 1 }}
											className="d-inline-block text-black  pb-0 mb-0"
										>
											{aiImplementationSection.item1}
										</p>
									</div>
									<div className="d-flex align-items-center pb-3">
										<FaCheck className="text-dark-blue me-3" />{" "}
										<p
											style={{ opacity: 1 }}
											className="d-inline-block text-black pb-0 mb-0"
										>
											{aiImplementationSection.item2}
										</p>
									</div>
									<div className="d-flex align-items-center">
										<FaCheck className="text-dark-blue me-3" />{" "}
										<p
											style={{ opacity: 1 }}
											className="d-inline-block text-black pb-0 mb-0"
										>
											{aiImplementationSection.item3}
										</p>
									</div>
									<div className="pt-5">
										<Button
											className=" custom-gradient-button w-100 w-md-auto px-5 py-3    me-md-4 "
											href="#form"
										>
											{aiImplementationSection.button1Text}
										</Button>
										<div className="mt-4 mt-md-0 d-md-inline-block text-center w-100 w-md-auto text-md-start">
											<Link className="dark-blue-link " to="#form">
												{aiImplementationSection.button2Text}{" "}
												<span className="ms-2">></span>
											</Link>
										</div>
									</div>
								</Col>
								<Col className="d-none d-lg-block" lg={6}>
									<div style={{ overflow: "hidden", borderRadius: "24px" }}>
										<GatsbyImage
											style={{ height: "30rem" }}
											class="w-100 "
											image={
												aiImplementationSection.image.node.localFile
													.childImageSharp.gatsbyImageData
											}
											alt={aiImplementationSection.subtext}
										/>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 py-lg-7 bg-light-grey">
						<Container id="form">
							<Row className="justify-content-between">
								<Col lg={6}>
									{parse(contactUsFormSection.title)}
									<p className="py-4">{contactUsFormSection.text}</p>
								</Col>
								<Col lg={6} xl={5}>
									<div
										style={{
											borderRadius: "24px",
											border: "1.4px solid rgba(6, 13, 13, 0.10",
										}}
										className="bg-white p-4 p-md-5"
									>
										<ContactForm />
									</div>
								</Col>
							</Row>
						</Container>
					</section>
					<section
						style={{
							background:
								"linear-gradient(102deg, #35B5E6 1.58%, #023B7E 100%)",
						}}
						className="py-5 position-relative"
					>
						<div
							style={{ zIndex: 0 }}
							className="position-absolute d-none d-md-block end-0 bottom-0"
						>
							<img className="" src={BgImageContact} alt="" />
						</div>
						<div
							style={{ zIndex: 0 }}
							className="position-absolute end-0 d-md-none bottom-0"
						>
							<img className="" src={BgImageContactMobile} alt="" />
						</div>
						<Container style={{ zIndex: 1 }} className="position-relative">
							<Row className="align-items-center gy-4 gy-lg-0">
								<Col className="text-center text-md-start" md={6}>
									<p style={{ opacity: 1 }} className="text-white dm-bold mb-2">
										{contactUsSection.text}
									</p>
									{parse(contactUsSection.title)}
								</Col>
								<Col className="text-md-end text-center" md={6}>
									<Button
										variant="white"
										className="  w-100 w-md-auto px-5 py-3   "
										href="#form"
									>
										{contactUsSection.buttonText}
									</Button>
									<p style={{ opacity: 1 }} className="text-white mt-3">
										{contactUsSection.subtext}
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 py-lg-7">
						<Container>
							<Row className="gy-5 gy-lg-0">
								<Col lg={4}>
									<GatsbyImage
										style={{ maxWidth: "50px" }}
										image={
											officeDetailsSection.address1.icon.node.localFile
												.childImageSharp.gatsbyImageData
										}
										alt={officeDetailsSection.address1.title}
									/>
									<h2 className="py-4">
										{officeDetailsSection.address1.title}
									</h2>
									<a
										className="black-link"
										href={`mailto:${officeDetailsSection.address1.address}`}
									>
										{officeDetailsSection.address1.address}
									</a>
								</Col>
								<Col lg={4}>
									<GatsbyImage
										style={{ maxWidth: "50px" }}
										image={
											officeDetailsSection.address2.icon.node.localFile
												.childImageSharp.gatsbyImageData
										}
										alt={officeDetailsSection.address2.title}
									/>
									<h2 className="py-4">
										{officeDetailsSection.address2.title}
									</h2>
									<div className="d-block pb-3">
										<a
											className="black-link "
											href={`tel:${officeDetailsSection.address2.phoneNumber.replace(
												/[^\d+]/g,
												""
											)}`}
										>
											{officeDetailsSection.address2.phoneNumber}
										</a>
									</div>
									<a
										target="_blank"
										rel="noreferrer"
										className="black-link"
										href={officeDetailsSection.address2.addressLink}
									>
										{officeDetailsSection.address2.address}
									</a>
								</Col>
								<Col lg={4}>
									<GatsbyImage
										style={{ maxWidth: "50px" }}
										image={
											officeDetailsSection.address3.icon.node.localFile
												.childImageSharp.gatsbyImageData
										}
										alt={officeDetailsSection.address3.title}
									/>
									<h2 className="py-4">
										{officeDetailsSection.address3.title}
									</h2>
									<div className="d-block pb-3">
										<a
											className="black-link "
											href={`tel:${officeDetailsSection.address3.phoneNumber.replace(
												/[^\d+]/g,
												""
											)}`}
										>
											{officeDetailsSection.address3.phoneNumber}
										</a>
									</div>
									<a
										target="_blank"
										rel="noreferrer"
										className="black-link"
										href={officeDetailsSection.address3.addressLink}
									>
										{officeDetailsSection.address3.address}
									</a>
								</Col>
							</Row>
						</Container>
					</section>
				</Layout>
			</div>
		</>
	);
};

export default IndexPage;
