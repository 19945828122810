import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function ContactForm() {
	const [state, handleSubmit] = useForm("xyyrpejz");
	if (state.succeeded) {
		return (
			<div>
				{" "}
				<Form className="w-100" onSubmit={handleSubmit}>
					<Form.Group className="mb-3" controlId="name">
						<Form.Label className="dm-bold">Name *</Form.Label>
						<Form.Control
							required
							className="py-3"
							name="name"
							placeholder="i.e. Robbie Mould"
							type="text"
						/>
					</Form.Group>
					<ValidationError prefix="Name" field="name" errors={state.errors} />
					<Form.Group className="mb-3" controlId="email">
						<Form.Label className="dm-bold">Email *</Form.Label>
						<Form.Control
							required
							className="py-3"
							name="email"
							type="email"
							placeholder="i.e. @gmail.com"
						/>
					</Form.Group>
					<ValidationError prefix="Email" field="email" errors={state.errors} />

					<Form.Group className="mb-3" controlId="message">
						<Form.Label className="dm-bold">Message</Form.Label>
						<Form.Control
							className="py-3"
							name="message"
							placeholder="Share your thoughts"
							as="textarea"
							rows={4}
						/>
					</Form.Group>

					<ValidationError
						prefix="Message"
						field="message"
						errors={state.errors}
					/>

					<Form.Group className="mt-3" controlId="agreeToTerms">
						<Form.Check
							required
							type="checkbox"
							name="agreeToTerms"
							label={
								<div>
									I agree to Quotestar's{" "}
									<a
										href="/policies/terms-and-conditions-customer"
										target="_blank"
										className="black-link"
										rel="noopener noreferrer"
									>
										Privacy Policy and T&Cs
									</a>
									.
								</div>
							}
						/>
					</Form.Group>
					<ValidationError
						prefix="agreeToTerms"
						field="agreeToTerms"
						errors={state.errors}
					/>
					<Button
						className=" gradient-button mt-3 w-100  px-5 py-3  "
						type="submit"
						id="contact-send-btn"
					>
						Submit
					</Button>
				</Form>
				<div className="d-none">{(window.location = "/thank-you")}</div>
			</div>
		);
	}
	return (
		<Form className="w-100" onSubmit={handleSubmit}>
			<Form.Group className="mb-3" controlId="name">
				<Form.Label className="dm-bold">Name *</Form.Label>
				<Form.Control
					required
					className="py-3"
					name="name"
					placeholder="i.e. Robbie Mould"
					type="text"
				/>
			</Form.Group>
			<ValidationError prefix="Name" field="name" errors={state.errors} />
			<Form.Group className="mb-3" controlId="email">
				<Form.Label className="dm-bold">Email *</Form.Label>
				<Form.Control
					required
					className="py-3"
					name="email"
					type="email"
					placeholder="i.e. @gmail.com"
				/>
			</Form.Group>
			<ValidationError prefix="Email" field="email" errors={state.errors} />

			<Form.Group className="mb-3" controlId="message">
				<Form.Label className="dm-bold">Message</Form.Label>
				<Form.Control
					className="py-3"
					name="message"
					placeholder="Share your thoughts"
					as="textarea"
					rows={4}
				/>
			</Form.Group>

			<ValidationError prefix="Message" field="message" errors={state.errors} />

			<Form.Group className="mt-3" controlId="agreeToTerms">
				<Form.Check
					required
					type="checkbox"
					name="agreeToTerms"
					label={
						<div>
							I agree to the{" "}
							<a
								href="/policies/terms-of-website-use"
								target="_blank"
								className="black-link"
								rel="noopener noreferrer"
							>
								terms & conditions
							</a>{" "}
							of mativus.
						</div>
					}
				/>
			</Form.Group>
			<ValidationError
				prefix="agreeToTerms"
				field="agreeToTerms"
				errors={state.errors}
			/>
			<Button
				className=" gradient-button mt-3 w-100  px-5 py-3  "
				type="submit"
				id="contact-send-btn"
			>
				Submit
			</Button>
		</Form>
	);
}
export default ContactForm;
