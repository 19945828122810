import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import parse from "html-react-parser";
import { Button } from "react-bootstrap";
import { Link } from "gatsby";

const ServicesSection = ({
	title1,
	description1,
	linkText1,
	image1,
	title2,
	description2,
	linkText2,
	image2,
	title3,
	description3,
	linkText3,
	image3,
	buttonText,
}) => {
	return (
		<Container className="py-lg-5 h-100">
			<Row className="justify-content-center pb-4 pb-lg-5 gy-4 gy-lg-0 h-100">
				<Col style={{ minHeight: "100%" }} lg={4} className="text-center">
					<div
						className="bg-light-grey position-relative h-100 p-4"
						style={{ borderRadius: "24px" }}
					>
						<GatsbyImage
							style={{ maxWidth: "70px" }}
							image={image1}
							alt={title1}
						/>
						<h2 className="pt-4">{title1}</h2>
						<p className="py-4">{description1}</p>
						<div className="d-none d-lg-block" style={{ height: "20px" }}></div>
						<Link className="black-link d-lg-none" to="#form">
							{linkText1} <span className="ms-2">></span>
						</Link>
						<div className="position-absolute d-none d-lg-block start-0 bottom-0 w-100 px-4 pb-4">
							<Link className="black-link " to="#form">
								{linkText1} <span className="ms-2">></span>
							</Link>
						</div>
					</div>
				</Col>
				<Col style={{ minHeight: "100%" }} lg={4} className="text-center">
					<div
						className="bg-light-grey h-100 p-4 position-relative"
						style={{ borderRadius: "24px" }}
					>
						<GatsbyImage
							style={{ maxWidth: "70px" }}
							image={image2}
							alt={title2}
						/>
						<h2 className="pt-4">{title2}</h2>
						<p className="py-4">{description2}</p>
						<div className="d-none d-lg-block" style={{ height: "20px" }}></div>
						<Link className="black-link d-lg-none" to="#form">
							{linkText2} <span className="ms-2">></span>
						</Link>
						<div className="position-absolute d-none d-lg-block start-0 bottom-0 w-100 px-4 pb-4">
							<Link className="black-link " to="#form">
								{linkText2} <span className="ms-2">></span>
							</Link>
						</div>
					</div>
				</Col>
				<Col style={{ minHeight: "100%" }} lg={4} className="text-center">
					<div
						className="bg-light-grey h-100 p-4 position-relative"
						style={{ borderRadius: "24px" }}
					>
						<GatsbyImage
							style={{ maxWidth: "70px" }}
							image={image3}
							alt={title3}
						/>
						<h2 className="pt-4">{title3}</h2>
						<p className="py-4">{description3}</p>
						<div className="d-none d-lg-block" style={{ height: "20px" }}></div>
						<Link className="black-link d-lg-none" to="#form">
							{linkText3} <span className="ms-2">></span>
						</Link>
						<div className="position-absolute d-none d-lg-block start-0 bottom-0 w-100 px-4 pb-4">
							<Link className="black-link " to="#form">
								{linkText3} <span className="ms-2">></span>
							</Link>
						</div>
					</div>
				</Col>
			</Row>
			<Row>
				<Col className="text-center">
					<Button
						className=" gradient-button mt-3 w-100 w-md-auto px-5 py-3  ms-auto  "
						href="#form"
					>
						{buttonText}
					</Button>
				</Col>
			</Row>
		</Container>
	);
};

export default ServicesSection;
