import React, { useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import gsap from "gsap";

const AiSection = ({
	img1,
	img2,
	img3,
	des1,
	des2,
	des3,
	title1,
	title2,
	title3,
}) => {
	const [hovered, setHovered] = useState("");
	const descriptionRefs = {
		marketing: useRef(),
		business: useRef(),
		operations: useRef(),
	};
	const imageRefs = {
		marketing: useRef(),
		business: useRef(),
		operations: useRef(),
	};
	const titleRefs = {
		marketing: useRef(),
		business: useRef(),
		operations: useRef(),
	};

	const handleMouseEnter = (key) => {
		setHovered(key);
		gsap.to(imageRefs[key].current, {
			scale: 1.05,
			height: "345px",
			opacity: 1,
			ease: "power1.inOut",
		});
		gsap.to(titleRefs[key].current, {
			opacity: 1,
			ease: "power1.inOut",
		});
		let fullHeight = descriptionRefs[key].current.scrollHeight + "px";
		gsap.fromTo(
			descriptionRefs[key].current,
			{ maxHeight: "0px" },
			{ maxHeight: fullHeight, ease: "power1.inOut", duration: 0.5 }
		);

		Object.entries(imageRefs).forEach(([refKey, ref]) => {
			if (refKey !== key) {
				gsap.to(ref.current, {
					opacity: 0,
					ease: "power1.inOut",
					height: "0px",
				});
			}
		});
		Object.entries(descriptionRefs).forEach(([refKey, ref]) => {
			if (refKey !== key) {
				gsap.to(ref.current, {
					maxHeight: 0,
					ease: "power1.inOut",
					duration: 0.5,
				});
			}
		});
	};

	const handleMouseLeave = () => {
		setHovered("");
		Object.values(imageRefs).forEach((ref) => {
			gsap.to(ref.current, {
				scale: 1,
				height: "120px",
				opacity: 1,
				ease: "power1.inOut",
			});
		});
		Object.values(descriptionRefs).forEach((ref) => {
			gsap.to(ref.current, {
				maxHeight: 0,
				ease: "power1.inOut",
				duration: 0.5,
			});
		});
		Object.values(titleRefs).forEach((ref) => {
			gsap.to(ref.current, { opacity: 0.45, ease: "power1.inOut" });
		});
	};

	const titleStyle = {
		height: "120px",

		width: "100%",
		marginBottom: "10px",
		opacity: 0.45, // Initial opacity set here
	};
	const descriptionStyle = {
		width: "100%",
		marginTop: "5px",

		overflow: "hidden",
		maxHeight: 0,
	};
	const imageContainerStyle = {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	};
	const imageStyle = {
		borderRadius: "24px",
		maxWidth: "100%",
		objectFit: "cover",
		width: "100%",
		height: "120px",
		marginBottom: "10px",
	};

	const renderDescription = (key) => {
		const descriptions = {
			marketing: des1,
			business: des2,
			operations: des3,
		};
		return (
			<p ref={descriptionRefs[key]} style={descriptionStyle}>
				{descriptions[key]}
			</p>
		);
	};

	return (
		<Container style={{ zIndex: 1 }} className="position-relative">
			<Row>
				<Col lg={8} xl={6} className="d-flex flex-column align-items-center">
					<div
						ref={titleRefs.marketing}
						style={titleStyle}
						onMouseEnter={() => handleMouseEnter("marketing")}
						onMouseLeave={handleMouseLeave}
					>
						<h3 className="display-5 text-dark-blue">{title1}</h3>
						{renderDescription("marketing")}
					</div>
					<div
						ref={titleRefs.business}
						style={titleStyle}
						onMouseEnter={() => handleMouseEnter("business")}
						onMouseLeave={handleMouseLeave}
					>
						<h3 className="display-5 text-dark-blue">{title2}</h3>
						{renderDescription("business")}
					</div>
					<div
						ref={titleRefs.operations}
						style={titleStyle}
						onMouseEnter={() => handleMouseEnter("operations")}
						onMouseLeave={handleMouseLeave}
					>
						<h3 className="display-5 text-dark-blue">{title3}</h3>
						{renderDescription("operations")}
					</div>
				</Col>
				<Col lg={4} xl={6} style={imageContainerStyle}>
					<img
						ref={imageRefs.marketing}
						src={img1}
						alt={title1}
						style={imageStyle}
					/>
					<img
						ref={imageRefs.business}
						src={img2}
						alt={title2}
						style={imageStyle}
					/>
					<img
						ref={imageRefs.operations}
						src={img3}
						alt={title3}
						style={imageStyle}
					/>
				</Col>
			</Row>
		</Container>
	);
};

export default AiSection;
